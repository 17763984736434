export const achievementIconMapper: Record<string, undefined> = {
  Info: undefined,
  TwoIsBetter: undefined,
  Welcome: undefined,
  Profile: undefined,
  Heart: undefined,
  Maniac: undefined,
  Share: undefined,
  Rock: undefined,
  Magic: undefined,
  Festival: undefined,
  Opera: undefined,
  Fidelity: undefined,
}
